import useJwt from '@/auth/jwt/useJwt'

function logout() {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem('accessToken')

  // Remove userData from localStorage
  localStorage.removeItem('userData')
}

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

// define route by user role
export const checkAuthorizeRole = url => {
  if (localStorage.getItem('userData') === null) {
    return 'auth-login'
  }

  // check role
  const userRole = JSON.parse(localStorage.getItem('userData')).roles[0].name.toLowerCase().replaceAll(' ', '')

  if (userRole === 'superadmin') {
    return `superadmin-${url}`
  }
  if (userRole === 'admin') {
    return `admin-${url}`
  }
  if (userRole === 'teacher') {
    return `teacher-${url}`
  }
  return `student-${url}`
}

export const errorHandler = (error, vm) => {
  // if request aborted
  if (error.message === 'Error: Request aborted') {
    return {
      status: 'error',
      type: 'modal',
      message: 'Something went wrong. Please try again.',
    }
  }

  if (error === 'Error: Request failed with status code 401' && vm.$route.name !== 'auth-login') {
    logout()
    return vm.$router.push({ name: 'auth-login' })
  }

  if (error === 'Error: Request failed with status code 403') {
    return vm.$router.push({ name: 'misc-not-authorized' })
  }

  if (error === 'Error: Request failed with status code 404') {
    return vm.$router.push({ name: 'misc-not-found' })
  }

  return vm.$router.push({ name: 'misc-server-error' })
}
