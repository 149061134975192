export default [
  // Teacher
  // Dashboard
  {
    path: '/teacher/dashboard',
    name: 'teacher-dashboard',
    component: () => import('@/views/apps/teacher/dashboard/Dashboard.vue'),
    meta: {
      role: 'teacher',
    },
  },

  // Classroom
  {
    path: '/teacher/classroom',
    name: 'teacher-classroom',
    component: () => import('@/views/apps/teacher/classroom/Classroom.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      pageTitle: 'Kelas',
      breadcrumb: [
        {
          text: 'Kelas',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/teacher/classroom/add', // Add classroom
  //   name: 'teacher-classroom-add',
  //   component: () => import('@/views/apps/teacher/classroom/classroom-add/ClassroomAdd.vue'),
  //   meta: {
  //     role: 'teacher',
  //     contentClass: 'teacher-classroom-menu',
  //     pageTitle: 'Kelas',
  //     back: 'classroom',
  //     breadcrumb: [
  //       {
  //         text: 'Kelas',
  //         to: 'classroom',
  //       },
  //       {
  //         text: 'Tambah',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // Classroom Detail
  {
    path: '/teacher/classroom/:id',
    name: 'teacher-classroom-detail',
    component: () => import('@/views/apps/teacher/classroom/classroom-detail/ClassroomDetail.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },

  // Classroom Grading Detail
  {
    path: '/teacher/classroom/:id/content/:contentId/task/:taskId/grade/:studentId',
    name: 'teacher-classroom-detail-grading',
    component: () => import('@/views/apps/teacher/classroom/classroom-grade-detail/ClassroomGradeDetail.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Nilai',
          active: true,
        },
      ],
    },
  },

  // Classroom Material
  {
    path: '/teacher/classroom/:id/content/:contentId/material',
    name: 'teacher-classroom-detail-material',
    component: () => import('@/views/apps/teacher/classroom/classroom-material/ClassroomMaterial.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/classroom/:id/content/:contentId/material/add',
    name: 'teacher-classroom-detail-material-add',
    component: () => import('@/views/apps/teacher/classroom/classroom-material/classroom-material-add/ClassroomMaterialAdd.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-material',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          to: 'classroom-detail-material',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Classroom Task
  // Get all task
  {
    path: '/teacher/classroom/:id/content/:contentId/task',
    name: 'teacher-classroom-detail-task',
    component: () => import('@/views/apps/teacher/classroom/classroom-task/ClassroomTask.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },

  // Add task
  {
    path: '/teacher/classroom/:id/content/:contentId/task/add',
    name: 'teacher-classroom-detail-task-add',
    component: () => import('@/views/apps/teacher/classroom/classroom-task/classroom-task-add/ClassroomTaskAdd.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Generate quiz pilgan
  {
    path: '/teacher/classroom/:id/content/:contentId/task/:taskId/generate/quiz/multiplechoice',
    name: 'teacher-classroom-detail-task-generate-quiz-multiplechoice',
    component: () => import('@/views/apps/teacher/classroom/classroom-task/classroom-task-add/generate-quiz/Multiplechoice.vue'),
    props: true,
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Buat Kuis',
          active: true,
        },
      ],
    },
  },

  // Generate quiz essay
  {
    path: '/teacher/classroom/:id/content/:contentId/task/:taskId/generate/quiz/essay',
    name: 'teacher-classroom-detail-task-generate-quiz-essay',
    component: () => import('@/views/apps/teacher/classroom/classroom-task/classroom-task-add/generate-quiz/Essay.vue'),
    props: true,
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Buat Kuis',
          active: true,
        },
      ],
    },
  },

  // Edit task
  {
    path: '/teacher/classroom/:id/content/:contentId/task/:taskId/edit',
    name: 'teacher-classroom-detail-task-edit',
    component: () => import('@/views/apps/teacher/classroom/classroom-task/classroom-task-edit/ClassroomTaskEdit.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Edit task quiz
  {
    path: '/teacher/classroom/:id/content/:contentId/task/:taskId/quiz/essay',
    name: 'teacher-classroom-detail-task-edit-quiz-essay',
    component: () => import('@/views/apps/teacher/classroom/classroom-task/classroom-task-edit/edit-quiz/Essay.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/classroom/:id/content/:contentId/task/:taskId/quiz/multiplechoice',
    name: 'teacher-classroom-detail-task-edit-quiz-multiplechoice',
    component: () => import('@/views/apps/teacher/classroom/classroom-task/classroom-task-edit/edit-quiz/Multiplechoice.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Classroom Exam
  // Get all exam
  {
    path: '/teacher/classroom/:id/content/:contentId/exam',
    name: 'teacher-classroom-detail-exam',
    component: () => import('@/views/apps/teacher/classroom/classroom-exam/ClassroomExam.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          active: true,
        },
      ],
    },
  },

  // Add exam
  {
    path: '/teacher/classroom/:id/content/:contentId/exam/add',
    name: 'teacher-classroom-detail-exam-add',
    component: () => import('@/views/apps/teacher/classroom/classroom-exam/classroom-exam-add/ClassroomExamAdd.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // Generate quiz pilgan
  {
    path: '/teacher/classroom/:id/content/:contentId/exam/:taskId/generate/quiz/multiplechoice',
    name: 'teacher-classroom-detail-exam-generate-quiz-multiplechoice',
    component: () => import('@/views/apps/teacher/classroom/classroom-exam/classroom-exam-add/generate-quiz/Multiplechoice.vue'),
    props: true,
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Buat Kuis',
          active: true,
        },
      ],
    },
  },

  // Generate quiz essay
  {
    path: '/teacher/classroom/:id/content/:contentId/exam/:taskId/generate/quiz/essay',
    name: 'teacher-classroom-detail-exam-generate-quiz-essay',
    component: () => import('@/views/apps/teacher/classroom/classroom-exam/classroom-exam-add/generate-quiz/Essay.vue'),
    props: true,
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Buat Kuis',
          active: true,
        },
      ],
    },
  },

  // Edit exam
  {
    path: '/teacher/classroom/:id/content/:contentId/exam/:taskId/edit',
    name: 'teacher-classroom-detail-exam-edit',
    component: () => import('@/views/apps/teacher/classroom/classroom-exam/classroom-exam-edit/ClassroomExamEdit.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Edit Exam Quiz
  {
    path: '/teacher/classroom/:id/content/:contentId/exam/:taskId/quiz/essay',
    name: 'teacher-classroom-detail-exam-edit-quiz-essay',
    component: () => import('@/views/apps/teacher/classroom/classroom-exam/classroom-exam-edit/edit-quiz/Essay.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/classroom/:id/content/:contentId/exam/:taskId/quiz/multiplechoice',
    name: 'teacher-classroom-detail-exam-edit-quiz-multiplechoice',
    component: () => import('@/views/apps/teacher/classroom/classroom-exam/classroom-exam-edit/edit-quiz/Multiplechoice.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // Classroom Forum
  // Get all forum
  {
    path: '/teacher/classroom/:id/content/:contentId/forum',
    name: 'teacher-classroom-detail-forum',
    component: () => import('@/views/apps/teacher/classroom/classroom-forum/ClassroomForum.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Forum',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/classroom/:id/content/:contentId/forum/:forumId',
    name: 'teacher-classroom-detail-forum-detail',
    component: () => import('@/views/apps/teacher/classroom/classroom-forum/classroom-forum-detail/ClassroomForumDetail.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Forum',
          active: true,
        },
      ],
    },
  },

  {
    path: '/teacher/classroom/:id/content/:contentId/forum/add',
    name: 'teacher-classroom-detail-forum-add',
    component: () => import('@/views/apps/teacher/classroom/classroom-forum/classroom-forum-add/ClassroomForumAdd.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-classroom-menu',
      navActiveLink: 'teacher-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-forum',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-forum',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // News Detail
  {
    path: '/teacher/news/:id',
    name: 'teacher-news-detail',
    component: () => import('@/views/apps/teacher/news/NewsDetail.vue'),
    meta: {
      role: 'teacher',
      contentClass: 'teacher-news',
      navActiveLink: 'teacher-news',
      pageTitle: 'Berita',
      back: 'dashboard',
      breadcrumb: [
        {
          text: 'Berita',
          to: 'dashboard',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },

  // Profile
  {
    path: '/teacher/profile/edit',
    name: 'teacher-profile-edit',
    component: () => import('@/views/apps/user/UserProfile.vue'),
    meta: {
      role: 'teacher',
      pageTitle: 'Profile',
      back: 'dashboard',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
]
