export default [
  // School Admin
  // Dashboard
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/apps/admin/dashboard/Dashboard.vue'),
    meta: {
      role: 'admin',
    },
  },
  // {
  //   path: '/admin/years',
  //   name: 'admin-study-years',
  //   component: () => import('@/views/apps/admin/study-years/StudyYears.vue'),
  //   meta: {
  //     role: 'admin',
  //   },
  // },
  {
    path: '/admin/teachers',
    name: 'admin-teachers',
    component: () => import('@/views/apps/admin/teachers/Teachers.vue'),
    meta: {
      role: 'admin',
    },
  },
  {
    path: '/admin/teachers/add',
    name: 'admin-teachers-add',
    component: () => import('@/views/apps/admin/teachers/TeachersAdd.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-teachers',
      navActiveLink: 'admin-teachers',
      pageTitle: 'Tambah Data Guru',
      back: 'teachers',
      breadcrumb: [
        {
          text: 'Guru',
          to: 'teachers',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/teachers/:id/edit',
    name: 'admin-teachers-edit',
    component: () => import('@/views/apps/admin/teachers/TeachersEdit.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-teachers',
      navActiveLink: 'admin-teachers',
      pageTitle: 'Edit Data Guru',
      back: 'teachers',
      breadcrumb: [
        {
          text: 'Guru',
          to: 'teachers',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/students',
    name: 'admin-students',
    component: () => import('@/views/apps/admin/students/Students.vue'),
    meta: {
      role: 'admin',
    },
  },
  {
    path: '/admin/students/add',
    name: 'admin-students-add',
    component: () => import('@/views/apps/admin/students/StudentsAdd.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-students',
      navActiveLink: 'admin-students',
      pageTitle: 'Tambah Data Siswa',
      back: 'students',
      breadcrumb: [
        {
          text: 'Siswa',
          to: 'students',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/students/detail',
    name: 'admin-students-detail',
    component: () => import('@/views/apps/admin/students/StudentsAdd.vue'),
    meta: {
      role: 'admin',
    },
  },
  {
    path: '/admin/students/:id/edit',
    name: 'admin-students-edit',
    component: () => import('@/views/apps/admin/students/StudentsEdit.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-students',
      navActiveLink: 'admin-students',
      pageTitle: 'Tambah Data Siswa',
      back: 'students',
      breadcrumb: [
        {
          text: 'Siswa',
          to: 'students',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/classrooms',
    name: 'admin-classrooms',
    component: () => import('@/views/apps/admin/classrooms/Classrooms.vue'),
    meta: {
      role: 'admin',
    },
  },
  {
    path: '/admin/classrooms/add',
    name: 'admin-classrooms-add',
    component: () => import('@/views/apps/admin/classrooms/ClassroomsAdd.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-classrooms',
      navActiveLink: 'admin-classrooms',
    },
  },
  {
    path: '/admin/classrooms/:id/edit',
    name: 'admin-classrooms-edit',
    component: () => import('@/views/apps/admin/classrooms/ClassroomsEdit.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-classrooms',
      navActiveLink: 'admin-classrooms',
      pageTitle: 'Edit Data Kelas',
      back: 'classrooms',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classrooms',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/classrooms/:id/add-students',
    name: 'admin-classrooms-add-students',
    component: () => import('@/views/apps/admin/classrooms/ClassroomsAddStudent.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-classrooms',
      navActiveLink: 'admin-classrooms',
      pageTitle: 'Tambah Siswa',
      back: 'classrooms',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classrooms',
        },
        {
          text: 'Tambah Siswa',
          active: true,
        },
      ],
    },
  },

  // News Detail
  {
    path: '/admin/news/:id',
    name: 'admin-news-detail',
    component: () => import('@/views/apps/admin/news/NewsDetail.vue'),
    meta: {
      role: 'admin',
      contentClass: 'admin-news',
      navActiveLink: 'admin-news',
      pageTitle: 'Berita',
      back: 'dashboard',
      breadcrumb: [
        {
          text: 'Berita',
          to: 'dashboard',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },

  // Alumni
  {
    path: '/admin/alumni',
    name: 'admin-alumni',
    component: () => import('@/views/apps/admin/alumni/Alumni.vue'),
    meta: {
      role: 'admin',
    },
  },

   // Alumni Add
   {
    path: '/admin/alumni/add',
    name: 'admin-alumni-add',
    component: () => import('@/views/apps/admin/alumni/AlumniAdd.vue'),
    meta: {
      role: 'admin',
    },
  },

   // Alumni Edit
   {
    path: '/admin/alumni/edit',
    name: 'admin-alumni-edit',
    component: () => import('@/views/apps/admin/alumni/AlumniEdit.vue'),
    meta: {
      role: 'admin',
    },
  },

  // Profile
  {
    path: '/admin/profile/edit',
    name: 'admin-profile-edit',
    component: () => import('@/views/apps/user/UserProfile.vue'),
    meta: {
      role: 'admin',
      pageTitle: 'Profile',
      back: 'dashboard',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },

]
