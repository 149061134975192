import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, checkAuthorizeRole } from '@/auth/utils'
import apps from './routes/apps'
import pages from './routes/pages'
import others from './routes/others'
import guide from './routes/guide'

// Role Router
import student from './routes/student'
import teacher from './routes/teacher'
import admin from './routes/admin'
import superadmin from './routes/superadmin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: checkAuthorizeRole('dashboard') } },
    ...apps,
    ...student,
    ...teacher,
    ...admin,
    ...superadmin,
    ...guide,
    ...pages,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // If auth required, check login. If login fails redirect to login page
  if (!isLoggedIn) {
    if (to.name === 'auth-login' || to.name === 'auth-callback' || to.name === 'sso-callback') {
      next()
    } else {
      next({ name: 'auth-login' })
    }
  }

  // If logged in, redirect to home page according to role
  if (to.name === 'auth-login' && isLoggedIn) {
    return next({ name: checkAuthorizeRole('dashboard') })
  }

  // If logged in, redirect to home page according to role
  if (to.meta.role) {
    if (to.meta.role !== getUserData().roles[0].name.toLowerCase().replaceAll(' ', '')) {
      return next({ name: 'misc-not-authorized' })
    }
  }
  
  // If route is not found
  if (!canNavigate(to)) {
    // Redirect to login if not logged in

    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(userData.roles[0].name === 'student' ? '/' : { name: 'access-control' })
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
