<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <!-- <buy-now /> -->
    <!-- <scroll-to-top v-if="enableScrollToTop" /> -->
  </div>
</template>

<script>
// import BuyNow from '@/components/BuyNow.vue'
// import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch, onMounted } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import axios from 'axios'
// import { useRouter } from 'vue-router'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,
    // BuyNow,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup(_, context) {
    const apiUrl = `${process.env.VUE_APP_BASE_API_URL}`
    const token = localStorage.getItem('accessToken')
    const router = context.root.$router
    const color = '#5f1fd5'

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    function checkAuthorizeRole(url) {
      // check role
      const userRole = JSON.parse(localStorage.getItem('userData'))
        .roles[0].name.toLowerCase()
        .replaceAll(' ', '')

      if (userRole === 'superadmin') {
        return `superadmin-${url}`
      }
      if (userRole === 'admin') {
        return `admin-${url}`
      }
      if (userRole === 'teacher') {
        return `teacher-${url}`
      }
      return `student-${url}`
    }

    function logout() {
      // check if there is any other user in localStorage
      if (localStorage.getItem('userDataList')) {
        const userList = JSON.parse(localStorage.getItem('userDataList'))
        const previousUserData = userList.pop()

        const accessToken = previousUserData.token
        const userData = previousUserData

        // Set accessToken in localStorage
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('userData', JSON.stringify(userData))

        // Reset User List is still exist
        if (userList.length === 0) {
          localStorage.removeItem('userDataList')
        } else {
          localStorage.setItem('userDataList', JSON.stringify(userList))
        }

        router.push({ name: checkAuthorizeRole('dashboard') }).then(() => {
          router.go()
        })
      } else {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem('accessToken')

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Redirect to login page
        router.push({ name: 'auth-login' })
      }
    }

    onMounted(async () => {
      await axios
        .get(`${apiUrl}/users/profile`, config)
        .then((response) => {
          // console.log(response.data)
        })
        .catch((error) => {
          if (
            error.response.status === 401 &&
            context.root.$route.name !== 'auth-login' &&
            context.root.$route.name !== 'sso-callback'
          ) {
            logout()
          }
        })
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
      checkAuthorizeRole,
    }
  },
}
</script>

<style scss>
.vgt-inner-wrap {
  box-shadow: none !important;
}
</style>
