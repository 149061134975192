export default {
  namespaced: true,
  state: {
    searchQuery: '',
    isFilter: false,
    isFilterApplied: false,
    filter: {
      school: null,
      schoolYear: null,
    },
  },
  getters: {
    searchQuery: state => state.searchQuery,
    isFilter: state => state.isFilter,
    isFilterApplied: state => state.isFilterApplied,
    filter: state => state.filter,
  },
  mutations: {
    SET_SEARCH_QUERY(state, val) {
      state.searchQuery = val
    },
    SET_IS_FILTER(state, val) {
      state.isFilter = val
    },
    SET_IS_FILTER_APPLIED(state, val) {
      state.isFilterApplied = val
    },
    SET_FILTER(state, val) {
      state.filter = val
    },
  },
  actions: {},
}
