export default [
  // Student
  // Dashboard
  {
    path: '/student/dashboard',
    name: 'student-dashboard',
    component: () => import('@/views/apps/student/dashboard/Dashboard.vue'),
    meta: {
      role: 'student',
    },
  },

  // Schedule
  {
    path: '/student/schedule',
    name: 'student-schedule',
    component: () => import('@/views/apps/student/schedule/Schedule.vue'),
    meta: {
      role: 'student',
      pageTitle: 'Jadwal',
      breadcrumb: [
        {
          text: 'Jadwal',
          active: true,
        },
      ],
    },
  },

  // Classroom
  {
    path: '/student/classroom',
    name: 'student-classroom',
    component: () => import('@/views/apps/student/classroom/Classroom.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      pageTitle: 'Kelas',
      breadcrumb: [
        {
          text: 'Kelas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id',
    name: 'student-classroom-detail',
    component: () => import('@/views/apps/student/classroom/classroom-detail/ClassroomDetail.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/material',
    name: 'student-classroom-detail-material',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-material/ClassroomMaterial.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Materi',
          active: true,
        },
      ],
    },
  },

  // Task
  {
    path: '/student/classroom/:id/content/:contentId/task',
    name: 'student-classroom-detail-task',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-task/ClassroomTask.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/task/:taskId/submission',
    name: 'student-classroom-detail-task-submission',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-task/ClassroomTaskSubmission.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Kerjakan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/task/:taskId/quiz',
    name: 'student-classroom-detail-task-quiz',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-task/ClassroomTaskQuiz.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Kerjakan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/task/:taskId/overview',
    name: 'student-classroom-detail-task-quiz-overview',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-task/ClassroomTaskQuizOverview.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Pratinjau Kuis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/task/:taskId/submission/result',
    name: 'student-classroom-detail-task-submission-result',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-task/ClassroomTaskSubmissionResult.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Hasil',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/task/:taskId/quiz/result',
    name: 'student-classroom-detail-task-quiz-result',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-task/ClassroomTaskQuizResult.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-task',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Tugas',
          to: 'classroom-detail-task',
        },
        {
          text: 'Hasil',
          active: true,
        },
      ],
    },
  },

  // Exam
  {
    path: '/student/classroom/:id/content/:contentId/exam',
    name: 'student-classroom-detail-exam',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-exam/ClassroomExam.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/exam/:taskId/submission',
    name: 'student-classroom-detail-exam-submission',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-exam/ClassroomExamSubmission.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Kerjakan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/exam/:taskId/quiz',
    name: 'student-classroom-detail-exam-quiz',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-exam/ClassroomExamQuiz.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Kerjakan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/exam/:taskId/overview',
    name: 'student-classroom-detail-exam-quiz-overview',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-exam/ClassroomExamQuizOverview.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Pratinjau Kuis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/exam/:taskId/submission/result',
    name: 'student-classroom-detail-exam-submission-result',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-exam/ClassroomExamSubmissionResult.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Hasil',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/exam/:taskId/quiz/result',
    name: 'student-classroom-detail-exam-quiz-result',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-exam/ClassroomExamQuizResult.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail-exam',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Ujian',
          to: 'classroom-detail-exam',
        },
        {
          text: 'Hasil',
          active: true,
        },
      ],
    },
  },

  // Forum
  {
    path: '/student/classroom/:id/content/:contentId/forum',
    name: 'student-classroom-detail-forum',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-forum/ClassroomForum.vue'),
    meta: {
      role: 'student',
      contentClass: 'student-classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Forum',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/classroom/:id/content/:contentId/forum/:forumId',
    name: 'student-classroom-detail-forum-detail',
    component: () => import('@/views/apps/student/classroom/classroom-detail/classroom-forum/classroom-forum-detail/ClassroomForumDetail.vue'),
    meta: {
      role: 'student',
      contentClass: 'student-classroom-menu',
      navActiveLink: 'student-classroom',
      pageTitle: 'Kelas',
      back: 'classroom-detail',
      breadcrumb: [
        {
          text: 'Kelas',
          to: 'classroom',
        },
        {
          text: 'Detail',
          to: 'classroom-detail',
        },
        {
          text: 'Forum',
          active: true,
        },
      ],
    },
  },

  // Exam
  {
    path: '/student/exam',
    name: 'student-exam',
    component: () => import('@/views/apps/student/exam/Exam.vue'),
    meta: {
      role: 'student',
      contentClass: 'classroom-menu',
      pageTitle: 'Ujian',
      breadcrumb: [
        {
          text: 'Ujian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/exam/:id',
    name: 'student-exam-detail',
    component: () => import('@/views/apps/student/exam/exam-detail/ExamDetail.vue'),
    meta: {
      role: 'student',
      contentClass: 'exam-menu',
      navActiveLink: 'exam',
      pageTitle: 'Ujian',
      breadcrumb: [
        {
          text: 'Ujian',
          to: 'exam',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/student/exam/:id/start',
    name: 'student-exam-session-start',
    component: () => import('@/views/apps/student/exam/exam-detail/ExamSession.vue'),
    meta: {
      role: 'student',
      contentClass: 'exam-menu',
      navActiveLink: 'exam',
      pageTitle: 'Ujian',
      breadcrumb: [
        {
          text: 'Ujian',
          to: 'exam',
        },
        {
          text: 'Detail',
        },
        {
          text: 'Sesi Ujian',
          active: true,
        },
      ],
    },
  },

  // News Detail
  {
    path: '/student/news/:id',
    name: 'student-news-detail',
    component: () => import('@/views/apps/student/news/NewsDetail.vue'),
    meta: {
      role: 'student',
      contentClass: 'student-news',
      navActiveLink: 'student-news',
      pageTitle: 'Berita',
      back: 'dashboard',
      breadcrumb: [
        {
          text: 'Berita',
          to: 'dashboard',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },

  // Profile
  {
    path: '/student/profile/edit',
    name: 'student-profile-edit',
    component: () => import('@/views/apps/user/UserProfile.vue'),
    meta: {
      role: 'student',
      pageTitle: 'Profile',
      back: 'dashboard',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
]
