export default [
  // Super Administrator
  // Dashboard
  {
    path: '/super/dashboard',
    name: 'superadmin-dashboard',
    component: () => import('@/views/apps/superadmin/dashboard/Dashboard.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // Teacher
  {
    path: '/super/teachers',
    name: 'superadmin-teachers',
    component: () => import('@/views/apps/superadmin/teachers/Teachers.vue'),
    meta: {
      role: 'superadmin',
    },
  },
  // Teacher Add
  {
    path: '/super/teachers/add',
    name: 'superadmin-teachers-add',
    component: () => import('@/views/apps/superadmin/teachers/TeachersAdd.vue'),
    meta: {
      role: 'superadmin',
      contentClass: 'superadmin-teachers',
      navActiveLink: 'superadmin-teachers',
    },
  },

  // Student
  {
    path: '/super/students',
    name: 'superadmin-students',
    component: () => import('@/views/apps/superadmin/students/Students.vue'),
    meta: {
      role: 'superadmin',
    },
  },
  // Student Add
  {
    path: '/super/students/add',
    name: 'admin-students-add',
    component: () => import('@/views/apps/superadmin/students/StudentsAdd.vue'),
    meta: {
      role: 'superadmin',
      contentClass: 'superadmin-students',
      navActiveLink: 'superadmin-students',
    },
  },
  // Student Detail
  {
    path: '/super/students/detail',
    name: 'superadmin-students-detail',
    component: () => import('@/views/apps/superadmin/students/StudentsAdd.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // Classroom
  {
    path: '/super/classrooms',
    name: 'superadmin-classrooms',
    component: () => import('@/views/apps/superadmin/classrooms/Classrooms.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // School
  {
    path: '/super/schools',
    name: 'superadmin-schools',
    component: () => import('@/views/apps/superadmin/schools/Schools.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // School Add
  {
    path: '/super/schools/add',
    name: 'superadmin-schools-add',
    component: () => import('@/views/apps/superadmin/schools/SchoolsAdd.vue'),
    meta: {
      role: 'superadmin',
      contentClass: 'superadmin-schools',
      navActiveLink: 'superadmin-schools',
      pageTitle: 'Sekolah',
      back: 'schools',
      breadcrumb: [
        {
          text: 'Sekolah',
          to: 'schools',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
    
  },
  {
    path: '/super/schools/:id/edit',
    name: 'superadmin-schools-edit',
    component: () => import('@/views/apps/superadmin/schools/SchoolsEdit.vue'),
    meta: {
      role: 'superadmin',
      contentClass: 'superadmin-schools',
      navActiveLink: 'superadmin-schools',
      pageTitle: 'Sekolah',
      back: 'schools',
      breadcrumb: [
        {
          text: 'Sekolah',
          to: 'schools',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // School
  {
    path: '/super/schools',
    name: 'superadmin-schools',
    component: () => import('@/views/apps/superadmin/schools/Schools.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // Years
  {
    path: '/super/years',
    name: 'superadmin-years',
    component: () => import('@/views/apps/superadmin/study-years/StudyYears.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // News
  {
    path: '/super/news',
    name: 'superadmin-news',
    component: () => import('@/views/apps/superadmin/news/News.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // News Add
  {
    path: '/super/news/add',
    name: 'superadmin-news-add',
    component: () => import('@/views/apps/superadmin/news/NewsAdd.vue'),
    meta: {
      role: 'superadmin',
      contentClass: 'superadmin-news',
      navActiveLink: 'superadmin-news',
      pageTitle: 'Berita',
      back: 'news',
      breadcrumb: [
        {
          text: 'Berita',
          to: 'news',
        },
        {
          text: 'Tambah',
          active: true,
        },
      ],
    },
  },

  // News Edit
  {
    path: '/super/news/:id/edit',
    name: 'superadmin-news-edit',
    component: () => import('@/views/apps/superadmin/news/NewsEdit.vue'),
    meta: {
      role: 'superadmin',
      contentClass: 'superadmin-news',
      navActiveLink: 'superadmin-news',
      pageTitle: 'Berita',
      back: 'news',
      breadcrumb: [
        {
          text: 'Berita',
          to: 'news',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },

  // News Detail
  {
    path: '/super/news/:id',
    name: 'superadmin-news-detail',
    component: () => import('@/views/apps/superadmin/news/NewsDetail.vue'),
    meta: {
      role: 'superadmin',
      contentClass: 'superadmin-news',
      navActiveLink: 'superadmin-news',
      pageTitle: 'Berita',
      back: 'news',
      breadcrumb: [
        {
          text: 'Berita',
          to: 'news',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },

  // Material Bank
  {
    path: '/super/material-bank',
    name: 'superadmin-material-bank',
    component: () => import('@/views/apps/superadmin/material-bank/Materials.vue'),
    meta: {
      role: 'superadmin',
    },
  },

  // Profile
  {
    path: '/super/profile/edit',
    name: 'superadmin-profile-edit',
    component: () => import('@/views/apps/user/UserProfile.vue'),
    meta: {
      role: 'superadmin',
      pageTitle: 'Profile',
      back: 'dashboard',
      breadcrumb: [
        {
          text: 'Profile',
          active: true,
        },
      ],
    },
  },
]
